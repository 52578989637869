import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export default function ModelList() {
    const [models, setModels] = useState([]);

    console.log(process.env);

    // This method fetches the models from the database.
    useEffect(() => {
        async function getModels() {
            const response = await fetch(`${process.env.REACT_APP_TELEMETRY}/model/`);

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }

            const models = await response.json();
            setModels(models);
        }

        getModels();

        return;
    }, [models.length]);

    // This method will delete a model from the database.
    async function deleteModel(id) {
        await fetch(`${process.env.REACT_APP_TELEMETRY}/model/${id}`, {
            method: "DELETE"
        });

        const newModels = models.filter((el) => el._id !== id);
        setModels(newModels);
    }

    const DeleteModel = (props) => (
        <IconButton
            aria-label="delete"
            onClick={() =>
                deleteModel(props.id)
            }
        >
            <DeleteIcon />
        </IconButton>
    );



    const columns = [
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'ipAddress', headerName: 'Model IP Address', width: 250 },
        {
            field: '_id',
            headerName: 'Delete',
            sortable: false,
            width: 120,
            renderCell: (params) => (
                <DeleteModel id={params.row._id} />
            )
        }

    ];

    const rowData = models;

    // This following section will display the table with the models.
    return (
        <div style={{ height: '500px', width: '100%' }}>
            <DataGrid
                rows={rowData}
                getRowId={(row) => row._id}
                columns={columns}
                pageSize={12}
                rowsPerPageOptions={[12]}
                checkboxSelection
            />
        </div>
    );
}