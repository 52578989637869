import { useEffect, useState, useContext } from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { grey, red, orange, blue } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
// import chroma from "chroma-js";
import { useSnackbar } from 'notistack';
import MetricLabel from './metriclabel';
import Typography from '@mui/material/Typography';
import { SocketContext } from './../context/socket';


export default function CassetteCard(props) {

    const socket = useContext(SocketContext);

    const [cassetteData, setCassetteData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [setpointOpen, setSetpointOpen] = useState(false);
    const [form, setForm] = useState({
        setpoint: ""
    });

    const { enqueueSnackbar } = useSnackbar();

    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    }

    function handleSetpoint() {
        setSendLoading(true);
        sendSetpoint();
    }

    function sendSetpoint() {
        const jsonbody = {
            setpoint: form.setpoint,
            ip: props.cassette.ipAddress
        };

        fetch(`${process.env.REACT_APP_TELEMETRY}/setcassette`, {
            method: 'POST',
            body: JSON.stringify(jsonbody),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            return res.json();
        })
            .then(json => {
                enqueueSnackbar('Setpoint sent successfully', { variant: 'success' });
                setSendLoading(false);
                setSetpointOpen(false)
            })
            .catch(err => {
                enqueueSnackbar('There was a problem sending the setpoint (see error): ' + err, { variant: 'error' });
            });
    };

    function handleClose() {
        setSendLoading(false);
        setSetpointOpen(false)
    }

    async function getCassetteData() {
        socket.emit("get cassette", props.cassette.ipAddress);
        return;
    }

    const weightColor = "#cc0000";
    const weightWidth = (100 * (cassetteData?.data?.env?.weight?.value / 1000)) + "%";

    socket.on("cassette", (cassetteObject) => {

        if (cassetteObject.ipAddress === props.cassette.ipAddress) {
            setLoading(true);
            setCassetteData(cassetteObject);
        }


    });


    // This method fetches the Cassette metrics from the database.
    useEffect(() => {

        const interval = setInterval(() => {
            getCassetteData()
        }, 5000)

        return () => getCassetteData(interval)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     // here we can use socket events and listeners
    //     return () => socket.disconnect(); //cleanup
    // }, [socket])

    return (
        <Paper elevation={0} sx={{
            backgroundColor: '#00000000',
            bgImage: 'none'
        }}>
            {
                loading ? (
                    <Card elevation={0} sx={{
                        // borderColor: "black",
                        // borderWidth: "1px",
                        // borderStyle: "solid",
                        backgroundColor: 'none',
                        height: 25,
                        bgImage: 'none',
                        py: 0
                    }}>

                        <Typography variant='h2' className='draggable-handle' sx={{
                            color: grey[500],
                            fontWeight: 'bold',
                            textAlign: 'left',
                            py: 0,
                            m: 0,
                            lineHeight: '22px',
                            textTransform: 'none',
                            fontSize: '25px'
                        }}>
                            {props.cassette.name}
                        </Typography>


                        <Box sx={{
                            width: 'calc(100% - 42px)',
                            height: "100%",
                            marginLeft: "42px",
                            display: "flex",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}>
                            <Box
                                onClick={() => setSetpointOpen(true)}
                                sx={{
                                    width: "41px",
                                    padding: "0 8px",
                                    margin: "0px",
                                    display: "flex",
                                    position: "absolute",
                                    left: "0px",
                                    top: "0px",
                                    bottom: "0px",
                                    cursor: "pointer",
                                }}>
                                <svg
                                    width="25px"
                                    height="25px"
                                    version="1.1"
                                    viewBox="200 205 1000 760"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g fill={red[300]}>
                                        <path d="m335.93 854.73 54.828 46.668 149.86-176.07-213.32-250.66 110.14-129.41-54.828-46.668-149.84 176.07 213.31 250.66z" />
                                        <path d="m604.08 901.4 149.86-176.07-213.32-250.66 110.13-129.41-54.824-46.668-149.86 176.07 213.32 250.66-110.13 129.41z" />
                                        <path d="m809.23 298.6-149.84 176.07 213.32 250.66-110.15 129.41 54.84 46.668 149.84-176.07-213.31-250.66 110.12-129.41z" />
                                    </g>
                                </svg>

                            </Box>

                            <MetricLabel
                                onClick={() => setSetpointOpen(true)}
                                left="31px"
                                top="1px"
                                color={red[500]}
                                value={cassetteData?.data?.env?.power?.value.toFixed(0)}
                                label=""
                                fontSize={10}
                                sx={{
                                    cursor: "pointer",
                                }}
                            />
                            <MetricLabel
                                onClick={() => setSetpointOpen(true)}
                                left="31px"
                                top="39%"
                                color="black"
                                value={cassetteData?.data?.env?.setpoint?.value.toFixed(0)}
                                label=""
                                units={String.fromCharCode(176)}
                                fontSize={10}
                                sx={{
                                    cursor: "pointer",
                                }}
                            />
                            <MetricLabel
                                onClick={() => setSetpointOpen(true)}
                                left="50px"
                                top="-4px"
                                color={orange[300]}
                                value={cassetteData?.data?.env?.temperature?.value.toFixed(0)}
                                label=""
                                units={String.fromCharCode(176)}
                                fontSize={22}
                            />
                            <Box sx={{
                                width: "33px",
                                padding: "0 4px",
                                margin: "0px",
                                display: "flex",
                                position: "absolute",
                                left: "74px",
                                top: "6px",
                                bottom: "0px",
                            }}>

                                <svg
                                    width="20px"
                                    height="20px"
                                    version="1.1"
                                    viewBox="0 0 1200 1200"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g fill={blue[300]}>
                                        <path d="m712.08 371.16-112.08-179.16-112.08 179.16c-56.762 90.602-151.92 257.76-151.92 358.8 0 153.24 118.44 278.04 264 278.04s264-124.8 264-278.04c0-101.04-95.16-268.2-151.92-358.8zm-163.2 38.16 51.121-81.602 51.121 81.602c88.199 141 140.88 260.88 140.88 320.64 0 12.754-1.125 25.48-3.3594 38.039h-377.28c-2.2344-12.559-3.3594-25.285-3.3594-38.039 0-59.762 52.68-179.64 140.88-320.64zm46.922 526.68h8.3984z" />
                                    </g>
                                </svg>

                            </Box>

                        </Box>

                        <MetricLabel
                            left="67%"
                            top="0%"
                            color={grey[500]}
                            fontSize={9}
                            label="Galaxy Black PLA"
                        />

                        <Box
                            width="33%"
                            height="50%"
                            display="flex"
                            justifyContent="right"
                            alignItems="right"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '0',
                                bottom: '0',
                                m: 0,
                                p: 0,
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: grey[500]
                            }}>
                            <Box
                                width={weightWidth}
                                height="100%"
                                sx={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    bottom: '0',
                                    m: 0,
                                    p: 0,
                                    backgroundColor: weightColor,
                                }}>

                            </Box>

                        </Box>

                    </Card>
                ) : (
                    <Box />
                )
            }
            <Dialog open={setpointOpen} onClose={handleClose}>
                <DialogTitle sx={{
                    color: grey[800],
                    fontWeight: 'bold',
                    fontFamily: 'Syncopate',
                    textAlign: 'center',
                    py: 6,
                    px: 8,
                    lineHeight: '22px',
                    textTransform: 'uppercase',
                    fontSize: '25px',
                }}>
                    Cassette {props.cassette.name}</DialogTitle>
                <DialogContent sx={{
                    justifyContent: 'center',
                    mx: 10,
                    my: 2,
                    textAlign: 'center',
                }}>
                    <TextField
                        autoFocus
                        id="setpoint"
                        label="Setpoint"
                        type="number"
                        placeholder={cassetteData?.data?.env?.setpoint?.value.toFixed(0)}
                        variant="outlined"
                        value={form.setpoint}
                        color="secondary"
                        focused
                        onChange={(e) => updateForm({ setpoint: e.target.value })}
                        sx={{
                            mt: 1,
                            fontSize: '8rem',
                            textAlign: 'center',
                            placeholderFontSize: '8rem',
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{
                    justifyContent: 'center',
                    m: 5,
                }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton
                        onClick={handleSetpoint}
                        endIcon={<SendIcon />}
                        loading={sendLoading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        SET
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Paper>

    );
}