import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export default function PrinterList() {
  const [printers, setPrinters] = useState([]);


  // This method fetches the printers from the database.
  useEffect(() => {
    async function getPrinters() {
      const response = await fetch(`${process.env.REACT_APP_TELEMETRY}/printer/`);

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const printers = await response.json();
      setPrinters(printers);
    }

    getPrinters();

    return;
  }, [printers.length]);

  // This method will delete a printer from the database.
  async function deletePrinter(id) {
    await fetch(`${process.env.REACT_APP_TELEMETRY}/printer/${id}`, {
      method: "DELETE"
    });

    const newPrinters = printers.filter((el) => el._id !== id);
    setPrinters(newPrinters);
  }

  const DeletePrinter = (props) => (
    <IconButton
      aria-label="delete"
      onClick={() =>
        deletePrinter(props.id)
      }
    >
      <DeleteIcon />
    </IconButton>
  );



  const columns = [
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'ipAddress', headerName: 'Octoprint IP Address', width: 250 },
    { field: 'apiKey', headerName: 'Octoprint API Key', sortable: false, width: 350 },
    {
      field: '_id',
      headerName: 'Delete',
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <DeletePrinter id={params.row._id} />
      )
    }

  ];

  const rowData = printers;

  // This following section will display the table with the printers.
  return (
    <div style={{ height: '500px', width: '100%' }}>
      <DataGrid
        rows={rowData}
        getRowId={(row) => row._id}
        columns={columns}
        pageSize={12}
        rowsPerPageOptions={[12]}
        checkboxSelection
      />
    </div>
  );
}