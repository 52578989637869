import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { StytchProvider } from "@stytch/react";
import { StytchHeadlessClient } from '@stytch/vanilla-js/headless';
import './App.css';
import { SocketContext, socket } from './context/socket';

// const STYTCH_PUBLIC_TOKEN = process.env.REACT_APP_STYTCH_PUBLIC_TOKEN;
// const stytch = window.Stytch(STYTCH_PUBLIC_TOKEN);

const stytch = new StytchHeadlessClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StytchProvider stytch={stytch}>
        <SocketContext.Provider value={socket}>
            <App />
        </SocketContext.Provider>
    </StytchProvider>
);
