import { Link, Outlet } from "react-router-dom";
import React, { useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import grey from '@mui/material/colors/grey';
import AccountMenu from './components/accountmenu';
import { useStytchUser } from "@stytch/react";
import { SocketContext } from './context/socket';


function Home() {

    const { user } = useStytchUser();

    const socket = useContext(SocketContext);

    const pages = ['Models', 'Printers', 'Cassettes'];

    useEffect(() => {
        socket.emit('user', user);
        // here we can use socket events and listeners

    }, [user, socket]);

    return (
        <div className="App">
            <AppBar position="static" elevation={0} sx={{
                backgroundColor: 'black',
            }}>
                <Container maxWidth={false}>
                    <Toolbar disableGutters>
                        <Typography
                            variant="h1"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                ml: 2,
                                display: { xs: 'flex', md: 'flex' },
                                fontFamily: 'Orbitron',
                                fontWeight: 700,
                                fontSize: '20px',
                                lineHeight: '32px',
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                textTransform: 'none',
                                textAlign: 'center'
                            }}
                        >
                            ITERATOR
                        </Typography>

                        <Box sx={{ ml: 10, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Link key={page} style={{ textDecoration: 'none' }} to={"/" + page} >
                                    <Button
                                        key={page}
                                        sx={{ my: 2, color: grey[300], textTransform: 'uppercase', textDecoration: 'none' }}
                                    >
                                        {page}
                                    </Button>
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <AccountMenu />
                        </Box>


                    </Toolbar>
                </Container>
            </AppBar>
            <Container maxWidth={false}>
                <Outlet />
            </Container>
        </div>
    );
}

export default Home;

