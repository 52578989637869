import * as React from 'react';
import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';


export default function MetricsOverlayBox(props) {

    return (
        <Box sx={{
            mx: 0,
            fontSize: 12,
            width: 80,
            p: 0,
            top: props.top,
            display: 'block',
            position: 'absolute',
            bottom: "-5px",
            right: 0,
            color: grey[300],
        }}>
            <svg viewBox="0 0 80 160 " xmlns="http://www.w3.org/2000/svg" sx={{
                position: 'absolute',
            }}>
                <polygon points="0,100 80,60 80,160 0,160" fill="#ffffffcc" />
            </svg>
            {props.children}
        </Box>

    );
}

