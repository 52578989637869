import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ModelList from '../components/modelList';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import CreateModel from '../components/createmodel';
import AddIcon from '@mui/icons-material/Add';

export default function Models() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <main style={{ padding: "1rem 0" }}>
            <Typography
                variant="h2"
                noWrap
                component="h2"
                href="/"
                sx={{
                    mr: 2,
                    mt: 6,
                    fontFamily: 'Syncopate',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                MODELS
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ModelList />
                </Grid>
                <Grid item xs={9}>

                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained"
                        endIcon={<AddIcon />}
                        color="primary"
                        onClick={handleOpen}
                        className="btn btn-primary"
                        size="large"
                        sx={{ width: '100%' }}
                    >Add Model</Button>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <CreateModel />
                </>

            </Modal>
        </main>
    );
}