import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStytch, useStytchSession } from "@stytch/react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';

const Authenticate = () => {
  const navigate = useNavigate();
  const stytch = useStytch();
  const [error, setError] = useState(null);
  const { session } = useStytchSession();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (session) {
      window.location.href = `${process.env.REACT_APP_INTERFACE}/`
      return;
    }
    stytch.oauth.authenticate(token, {
      session_duration_minutes: 1440,
    });
    const authPromise = stytch.oauth.authenticate(
      token,
      { session_duration_minutes: 1440 })

      .then(() => navigate("/"));

    authPromise.catch((err) => {
      setError(err);
      setTimeout(() => navigate("/login"), 2000);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid sx={{ flexGrow: 1 }} container minHeight={600}>
        <Grid xs={12} item display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h2"
            noWrap
            component="h2"
            href="/"
            sx={{
              mt: 6,
              width: '100%',
              fontFamily: 'Syncopate',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textAlign: 'center',
              textDecoration: 'none',
            }}
          >
            Authenticating...
            {error ? (
              <>
                {" "}
                An error has occurred: <br />
                <pre>{String(error)}</pre>
              </>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <Box sx={{ width: '100%', marginTop: 0, paddingTop: 0 }}>
            <LinearProgress sx={{
              marginTop: 0,
              paddingTop: 0
            }} />
          </Box>

        </Grid>
      </Grid>
    </Container>
  );
};

export default Authenticate;