import * as React from 'react';
import { useState } from 'react';
import DebugPrint from '../tools/debugPrint';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

// custom hook to get the current pathname in React



export default function OctoJob(props) {

  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  }

  const { enqueueSnackbar } = useSnackbar();

  const [octoJob, setOctoJob] = useState([]);

  const printerIP = usePathname();

  getOctoJob();


  async function getOctoJob() {
    const response = await fetch(`${process.env.REACT_APP_TELEMETRY}/octojob/${printerIP}`);

    if (!response.ok) {
      enqueueSnackbar(`An error occurred: ${response.statusText}`, { variant: 'error' });;
      return;
    }

    const result = await response.json();
    setOctoJob(result);

  }

  return (
    <DebugPrint data={octoJob.data} IP={printerIP} />

  );
}
