import { useEffect, useState, useContext } from 'react';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { grey, red, orange } from '@mui/material/colors';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MetricsOverlayBox from './metricsoverlaybox';
import MetricLabel from './metriclabel';
import CircularProgress from '@mui/material/CircularProgress';
import { SocketContext } from './../context/socket';
import NightlightIcon from '@mui/icons-material/Nightlight';
import LightModeIcon from '@mui/icons-material/LightMode';
import { DataGrid } from '@mui/x-data-grid';


export default function PrinterCard(props) {

  const socket = useContext(SocketContext);

  const item = {
    src: "models/prusa.png",
    name: "Prusa MK3S"
  }

  const [octoPrinter, setOctoPrinter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const printerIP = props.printer.ipAddress;
  const [webcamUrl, setWebcamUrl] = useState('https://telemetry.postbinary.dev/video/' + printerIP + '.jpg');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '0',
    boxShadow: 14,
    p: 6,
    '&.Mui-focused': { outline: 'none' },
    '&:focus': { outline: 'none' },
    '&:active': { outline: 'none' },
  };

  socket.on("connection_error", (err) => {
    console.log(err);
  });

  socket.on("printer", (printerObject) => {
    if (printerObject.ipAddress === printerIP) {
      setLoading(true);
      setOctoPrinter(printerObject);
      setProgress(printerObject.data.job.progress.completion);
      let timeLeft = printerObject.data.job.progress.printTimeLeft;
      timeLeft = timeLeft / 60;
      timeLeft = timeLeft.toFixed(0);
      setRemaining(timeLeft);
    }
  });


  function lightson() {
    console.log('lights fired!!!');
    const msg = { "seg": [{ "col": [[0, 255, 200]] }] };
    const url = "http://192.168.1.4/json"
    socket.emit('led', msg, url);
  }

  function cameraLed(r, g, b, w, brightness) {
    console.log('camera lights fired!!!');
    const msg = {
      "command": "fill",
      "segment": "camera",
      "color": {
        "r": r,
        "g": g,
        "b": b,
        "w": w,
        "alpha": brightness
      },
    };
    const url = "http://" + printerIP + ":5001/leds"
    socket.emit('led', msg, url);
  }

  // This method fetches the printers from the database.
  useEffect(() => {

    function getOctoPrinter() {
      socket.emit("get printer", printerIP);
      setWebcamUrl('https://telemetry.postbinary.dev/video/' + printerIP + '.jpg?' + Date.now())
    }
    getOctoPrinter();
    setInterval(function () {
      getOctoPrinter();
    }, 4000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { field: 'name', headerName: 'Name' },
    {
      field: 'thumbnail',
      headerName: 'Preview',
      sortable: false,
      renderCell: (params) => (
        `http://${printerIP}/${params.row.thumbnail}`
      )
    }
  ];

  const rowData = octoPrinter?.data?.files?.files;

  return (
    <Paper elevation={0} sx={{
      bgColor: "#00000000",
      background: "none"
    }}>
      {
        loading ? (
          <Card elevation={0}>
            <CardHeader
              avatar={
                <Avatar className='draggable-handle' sx={{ color: grey[900], borderRadius: 0, bgcolor: '#00000000', height: 25, fontSize: 36, py: 0, pr: 6, pl: 2.5, pt: '3px', fontWeight: 100, fontFamily: 'Syncopate' }} size='small' aria-label="name">
                  {props.printer.name}
                </Avatar>
              }
              sx={{
                px: 0,
                pt: 0,
                mt: 0
              }}
            />
            <CardContent sx={{
              p: 0,
              position: 'relative',
            }}>
              <div style={{
                height: '200px',
                width: '100%',
                position: 'relative',
              }}>
                <div
                  onClick={() => {
                    lightson();
                  }}
                  style={{
                    position: 'absolute',
                    backgroundImage: `url(${item.src})`,
                    backgroundSize: '100% 100%',
                    backgroundPosition: 'right top',
                    backgroundRepeat: 'no-repeat',
                    width: '40%',
                    height: '50%',
                    top: 0,
                    right: 0,
                    // filter: 'opacity(0.6)',
                  }} />
                {/* <Canvas
                  camera={{ position: [0, 15, 30], fov: 20 }}
                  style={{
                    backgroundColor: '#00000000',
                    width: '200px',
                    height: '200px',
                    position: 'absolute',
                    // left: '53%',
                    // top: '63%',
                    // transform: 'translate(-50%, -50%)',
                    filter: 'saturation(1)',
                  }}
                >
                  <ambientLight intensity={0.2} />
                  <directionalLight intensity={0.7} position={[100, 20, 0]} />
                  <Model />
                  <OrbitControls />
                </Canvas> */}

                <MetricsOverlayBox top={120}>
                  <MetricLabel top={4} right={42} label="TOOL: " color={grey[700]} />
                  <MetricLabel top={1} left={40} fontSize={18} value={octoPrinter?.data?.printers?.temperature?.tool0?.actual.toFixed(0)} color={orange[400]} units={String.fromCharCode(176)} />
                  <MetricLabel top={26} right={42} label="BED: " color={grey[700]} />
                  <MetricLabel top={23} left={40} fontSize={18} value={octoPrinter?.data?.printers?.temperature?.bed?.actual.toFixed(0)} color={red[700]} units={String.fromCharCode(176)} />
                </MetricsOverlayBox>
                <MetricLabel top={180} left={10} label="Status: " onClick={handleOpen} color={grey[800]} sx={{ cursor: 'pointer' }} />
                <MetricLabel top={175} left={60} fontSize={17} value={octoPrinter?.data?.printers?.state.text} color={grey[500]} />
                <MetricLabel top={155} left={60} fontSize={10} value={octoPrinter?.data?.job?.job?.file?.name?.substring(0, 20)} color={grey[500]} />

                <CircularProgress
                  top={0}
                  left={0}
                  variant="determinate"
                  value={progress}
                  sx={{
                    left: 10,
                    bottom: 50,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }} />
                <MetricLabel top={200} left={60} fontSize={14} value={remaining + 'min'} color={grey[800]} />

                <img src={webcamUrl} alt="webcam" style={{
                  width: '55%',
                  height: '50%',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }} />
              </div>
            </CardContent>



          </Card>
        ) : (
          <Box />
        )
      }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ color: grey[900], borderRadius: 0, bgcolor: '#00000000', fontSize: 26, pt: '3px', fontWeight: 100, fontFamily: 'Syncopate' }}>
              {props.printer.name}
            </Typography>
            <div style={{
              width: '60%',
              float: 'left',
            }}>
              <img src={webcamUrl} alt="webcam" style={{
                width: '90%',
                // position: 'absolute',
                top: 0,
                left: 0,
              }} />
            </div>
            <div style={{
              width: '40%',
              float: 'right',
            }}>
              <Typography variant="h6" component="h6" sx={{ color: grey[900], borderRadius: 0, bgcolor: '#00000000', fontSize: 16, pt: '3px', fontWeight: 100, fontFamily: 'Syncopate' }}>
                Printer Lights
              </Typography>
              <Button variant="outlined" startIcon={<NightlightIcon />} onClick={() => cameraLed(0, 0, 0, 0, 0)}>
                Lights Off
              </Button>
              <Button variant="outlined" startIcon={<LightModeIcon />} onClick={() => cameraLed(255, 255, 255, 255, 1)}>
                Lights On
              </Button>
              <Button variant="outlined" startIcon={<LightModeIcon />} onClick={() => cameraLed(255, 0, 0, 0, 1)}>
                Red
              </Button>
              <Button variant="outlined" startIcon={<LightModeIcon />} onClick={() => cameraLed(0, 255, 0, 0, 1)}>
                Green
              </Button>
              <Button variant="outlined" startIcon={<LightModeIcon />} onClick={() => cameraLed(0, 0, 255, 0, 1)}>
                Blue
              </Button>
              <Typography variant="h6" component="h6" sx={{ color: grey[900], borderRadius: 0, bgcolor: '#00000000', fontSize: 16, pt: '3px', fontWeight: 100, fontFamily: 'Syncopate' }}>
                Overhead Lights
              </Typography>
              <Button variant="outlined" startIcon={<NightlightIcon />} onClick={lightson}>
                Lights Off
              </Button>
              <Button variant="outlined" startIcon={<LightModeIcon />} onClick={() => cameraLed(255, 255, 255, 255, 1)}>
                Lights On
              </Button>
            </div>
            <div style={{ float: "left", height: 400, width: '100%' }}>
              <DataGrid
                rows={rowData}
                getRowId={(row) => row.hash}
                columns={columns}
                pageSize={12}
                rowsPerPageOptions={[12]}
                checkboxSelection
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </Paper>


  );
}


