import React from 'react';
import { useStytch } from '@stytch/react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button, Typography } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';

export default function Login() {

  const stytchClient = useStytch();

  const startOAuth = () => stytchClient.oauth.github.start({
    loginRedirectURL: `${process.env.REACT_APP_INTERFACE}/authenticate`,
    signupRedirectURL: `${process.env.REACT_APP_INTERFACE}/authenticate`,
    custom_scopes: ['read:user'],
    publicToken: process.env.REACT_APP_STYTCH_PUBLIC_TOKEN
  });

  return (
    <Container maxWidth="lg">
      <Grid sx={{ flexGrow: 1 }} container minHeight={600}>
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" height="100vh">
          <div className="sign-in-container">

            <Typography
              variant="h1"
              noWrap
              component="h1"
              href="/"
              sx={{
                fontFamily: 'Syncopate',
                fontWeight: 300,
                letterSpacing: '6rem',
                color: 'inherit',
                textAlign: 'center',
                textDecoration: 'none',
                marginRight: '-8rem',
                px: 20,
                fontSize: '3rem',
                textShadow: '0px 0px 30px #0e90d1ff',
                lineHeight: '8rem'
              }}
            >
              postbinary
            </Typography>
            <Button variant="outlined" startIcon={<GitHubIcon fontSize='large' size='medium' />} size="medium" onClick={startOAuth} sx={{
              textAlign: 'center',
              fontWeight: 700,
              m: 0,
              mx: 'auto',
              mt: 20,
              width: '25%',
              left: '50%',
              transform: 'translate(-50%, 0)'

            }}>
              Sign In with GitHub
            </Button>

          </div>
        </Grid>
      </Grid>
    </Container>
  );
};