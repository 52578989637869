import React, { useState } from "react";
import { useNavigate } from "react-router";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

export default function CreatePrinter() {
  const [form, setForm] = useState({
    name: "",
    octoprintIP: "",
    apiKey: "",
  });
  const navigate = useNavigate();

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();

    // When a post request is sent to the create url, we'll add a new printer to the database.
    const newPrinter = { ...form };

    await fetch(`${process.env.REACT_APP_TELEMETRY}/printer/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPrinter),
    })
      .catch(error => {
        window.alert(error);
        return;
      });

    console.log(newPrinter);
    console.log(JSON.stringify(newPrinter));
    setForm({ name: "", octoprintIP: "", apiKey: "" });
    navigate("/Printers");
  }

  // Styling for Modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
  };


  // This following section will display the form that takes the input from the user.
  return (
    <Box sx={style}>
      <Typography sx={{ textAlign: 'center' }} variant="h3">Add New printer</Typography>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { mt: 2, mb: 2, width: '100%' },
          '& .MuiButton-root': { mt: 2, mb: 2, width: '100%' },
        }}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >

        <TextField
          label="Name"
          variant="filled"
          type="text"
          className="form-control"
          id="name"
          value={form.name}
          onChange={(e) => updateForm({ name: e.target.value })}
        />
        <TextField
          label="Octoprint IP"
          variant="filled"
          type="text"
          className="form-control"
          id="octoprintIP"
          value={form.octoprintIP}
          onChange={(e) => updateForm({ octoprintIP: e.target.value })}
        />

        <TextField
          label="Octoprint API Key"
          variant="filled"
          type="text"
          className="form-control"
          id="apiKey"
          value={form.apiKey}
          onChange={(e) => updateForm({ apiKey: e.target.value })}
        />

        <Button variant="contained"
          endIcon={<AddIcon />}
          type="submit"
          value="Add Printer"
          className="btn btn-primary"
          size="large"
        >
          Add Printer
        </Button>
      </Box>
    </Box>
  );
}