import styles from './debugPrint.css'
import React from 'react'

export default function DebugPrint (props) {
  // constructor(props) {
  //   super(props)
  //   autoBind(this)
  //   this.state = {
  //     show: false,
  //   }
  // }    

  // toggle() {
  //   this.setState({
  //     show: !this.state.show,
  //   });
  // }

    return (
      <div style={styles.root}>
        <div style={styles.header} onClick={this.toggle}>
          <strong>Printer IP: {props.IP}</strong>
        </div>
            <pre style={styles.pre}>
              {JSON.stringify(this.props.data, null, 2) }
            </pre>
      </div>
    )
  
  
}
