import * as React from 'react';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';

export default function MetricLabel(props) {

    return (
        <Typography variant="h6" component="span" onClick={props.onClick} sx={{
            mx: 0,
            fontSize: props.fontSize ? props.fontSize : 12,
            fontWeight: 500,
            p: 0,
            height: '20px',
            display: 'block',
            position: 'absolute',
            left: props.left ? props.left : 'auto',
            top: props.top ? props.top : 'auto',
            right: props.right ? props.right : 'auto',
            color: props.color ? props.color : grey[300],
        }}>
            {props.label ? props.label : null}{props.value ? props.value : null}{props.units ? props.units : null}
        </Typography>
    );
}

