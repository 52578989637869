import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
 
export default function CassetteList() {
  const [cassettes, setCassettes] = useState([]);
 
    console.log(process.env);
 
 // This method fetches the cassettes from the database.
 useEffect(() => {
   async function getCassettes() {
     const response = await fetch(`${process.env.REACT_APP_TELEMETRY}/cassette/`);
 
     if (!response.ok) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }
 
     const cassettes = await response.json();
     setCassettes(cassettes);
   }
 
   getCassettes();
 
   return;
 }, [cassettes.length]);
 
 // This method will delete a cassette from the database.
 async function deleteCassette(id) {
   await fetch(`${process.env.REACT_APP_TELEMETRY}/cassette/${id}`, {
     method: "DELETE"
   });
 
   const newCassettes = cassettes.filter((el) => el._id !== id);
   setCassettes(newCassettes);
 }

const DeleteCassette = (props) => (
  <IconButton 
    aria-label="delete"
    onClick= {() => 
      deleteCassette(props.id)
    }
    >
    <DeleteIcon />
  </IconButton>
);
  
  

 const columns = [
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'ipAddress', headerName: 'Cassette IP Address', width: 250 },
    { field: '_id', 
      headerName: 'Delete', 
      sortable: false, 
      width: 120,
      renderCell: (params) => (
        <DeleteCassette id={params.row._id} />
      )    
    }

  ];

  const rowData = cassettes;

 // This following section will display the table with the cassettes.
 return (
  <div style={{ height: '500px', width: '100%' }}>
      <DataGrid
        rows={rowData}
        getRowId={(row) => row._id}
        columns={columns}
        pageSize={12}
        rowsPerPageOptions={[12]}
        checkboxSelection
      />
     </div>
 );
}