import { useEffect, useState, useContext } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Responsive, WidthProvider } from "react-grid-layout";
import PrinterCard from '../components/printercard';
import CassetteCard from '../components/cassettecard';
import { useStytchUser } from "@stytch/react";
import { SocketContext } from '../context/socket';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';


export default function Dashboard() {

  const socket = useContext(SocketContext);

  const [printers, setPrinters] = useState([]);
  const [cassettes, setCassettes] = useState([]);

  const [layoutCtop, setLayoutCtop] = useState({});
  const [layoutPrinters, setLayoutPrinters] = useState({});
  const [layoutCbottom, setLayoutCbottom] = useState({});

  const ResponsiveGridLayout = WidthProvider(Responsive);

  const { user } = useStytchUser();


  useEffect(() => {

    function getLayouts() {

      // socket.emit("getUserPreferences", user, "layoutCTop", (response) => {
      //   console.log(response);
      //   setLayoutCtop(response.layoutCtop);
      //   console.log("retrieved top");
      // });
      // socket.emit("getUserPreferences", user, "layoutPrinters", (response) => {
      //   console.log(response);
      //   setLayoutPrinters(response.layoutPrinters);
      //   console.log("retrieved printers");
      // });
      // socket.emit("getUserPreferences", user, "layoutCbottom", (response) => {
      //   console.log(response);
      //   setLayoutCbottom(response.layoutCbottom);
      //   console.log("retrieved bottom");
      // });

    }

    getLayouts();

    return;
  }, [socket, user]);


  async function getPrinterList() {
    const response = await fetch(`${process.env.REACT_APP_TELEMETRY}/printer/`);

    if (!response.ok) {
      const message = `An error occurred: ${response.statusText}`;
      window.alert(message);
      return;
    }

    const printers = await response.json();
    setPrinters(printers);
    console.log(printers);
  }

  async function getCassetteList() {
    const response = await fetch(`${process.env.REACT_APP_TELEMETRY}/cassette/`);

    if (!response.ok) {
      const message = `An error occurred: ${response.statusText}`;
      window.alert(message);
      return;
    }

    const cassettes = await response.json();
    setCassettes(cassettes);
    console.log(cassettes);
  }


  // This method fetches the printers from the database.
  useEffect(() => {

    getPrinterList();

    return;
  }, []);

  // This method fetches the printers from the database.
  useEffect(() => {

    getCassetteList();

    return;
  }, []);

  function saveLayouts() {
    socket.emit("userPreferences", user, "layoutCtop", layoutCtop);
    socket.emit("userPreferences", user, "layoutPrinters", layoutPrinters);
    socket.emit("userPreferences", user, "layoutCbottom", layoutCbottom);
    console.log("saved layouts");
  }

  function onLayoutChangeCtop(layout, layouts) {
    setLayoutCtop(layouts);
    console.log('layoutCtop Set!')
    console.log(layoutCtop);
    return;
  }
  function onLayoutChangePrinters(layout, layouts) {
    setLayoutPrinters(layouts);
    console.log('layoutPrinters Set!')
    console.log(layoutPrinters);
    return;
  }
  function onLayoutChangeCbottom(layout, layouts) {
    setLayoutCbottom(layouts);
    console.log('layoutCbottom Set!')
    console.log(layoutCbottom);
    return;
  }

  return (

    <main style={{}}>
      <header className="App-header" style={{ height: '40px' }}>
        <IconButton color='secondary' onClick={() => { saveLayouts(); console.log(layoutCtop); console.log(layoutPrinters); console.log(layoutCbottom); }} sx={{ float: "right" }} component="button">
          <SaveIcon />
        </IconButton>
      </header>
      <ResponsiveGridLayout
        className="layout"
        id='top'
        rows={5}
        rowHeight={25}
        layouts={layoutCtop}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 6, md: 3, sm: 1, xs: 1, xxs: 1 }}
        isResizable={false}
        isBounded={false}
        isDroppable={true}
        margin={[16, 12]}
        compactType='vertical'
        onLayoutChange={onLayoutChangeCtop}
        autoSize={true}
        isDraggable={true}
        draggableHandle=".draggable-handle"
      >
        {
          cassettes.map((cassette, index) =>
            <Grid key={cassette._id} item data-grid={{ isDraggable: true, x: 1, y: index, w: 1, h: 1 }}>
              <CassetteCard cassette={cassette} />
            </Grid>
          )
        }
      </ResponsiveGridLayout>


      <ResponsiveGridLayout
        className="layout"
        rows={2}
        rowHeight={266}
        layouts={layoutPrinters}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 6, md: 3, sm: 1, xs: 1, xxs: 1 }}
        isResizable={false}
        isBounded={false}
        compactType='vertical'
        onLayoutChange={onLayoutChangePrinters}
        margin={[16, 16]}
        autoSize={true}
        draggableHandle=".draggable-handle"
      >
        {
          printers.map((printer, index) =>
            <Grid key={printer._id} item data-grid={{ x: index, y: 0, w: 1, h: 1 }}>
              <PrinterCard printer={printer} />
            </Grid>
          )
        }
      </ResponsiveGridLayout>

      <ResponsiveGridLayout
        className="layout"
        rows={5}
        id='bottom'
        rowHeight={25}
        layouts={layoutCbottom}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 6, md: 3, sm: 1, xs: 1, xxs: 1 }}
        isResizable={false}
        isBounded={false}
        isDroppable={true}
        onLayoutChange={onLayoutChangeCbottom}
        margin={[16, 12]}
        compactType='vertical'
        autoSize={true}
        isDraggable={true}
        draggableHandle=".draggable-handle"
      >
        {
          cassettes.map((cassette, index) =>
            <Grid key={cassette._id} item data-grid={{ isDraggable: true, x: 1, y: index, w: 1, h: 1 }}>
              <CassetteCard cassette={cassette} />
            </Grid>
          )
        }
      </ResponsiveGridLayout>


    </main>
  );
}
