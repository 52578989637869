import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './index.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import Home from './Home';
import Dashboard from './routes/dashboard';
import Cassettes from './routes/cassettes';
import Printers from './routes/printers';
import Models from './routes/models';
import OctoJob from './routes/octojob';
import Login from './routes/login';
import Authenticate from './routes/authenticate';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/orbitron"
import { RequireLogin, RequireLoggedOut } from "./Stytch";



const darkTheme = createTheme({
  typography: {
    fontFamily: [
      '"Roboto"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 14,
  },
});

darkTheme.typography.h1 = {
  fontFamily: 'Orbitron',
  margin: '16px',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '32px',
  letterSpacing: '.3rem',
  textTransform: 'uppercase',
  color: 'inherit',
  textDecoration: 'none',
};

darkTheme.typography.h2 = {
  fontFamily: 'Syncopate',
  margin: '16px',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '32px',
  letterSpacing: '.2rem',
  textTransform: 'uppercase',
};
darkTheme.typography.h3 = {
  fontFamily: 'Syncopate',
  margin: '16px',
  marginBottom: '32px',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '.1rem',
  textTransform: 'lowercase',
};



function App() {


  return (
    <ThemeProvider theme={darkTheme}>

      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <Routes>
            <Route path="/authenticate" element={<Authenticate />} />
            <Route path="/login" element={<RequireLoggedOut><Login /></RequireLoggedOut>} />
            <Route path="/" element={<RequireLogin><Home /></RequireLogin>}>
              <Route index element={<RequireLogin><Dashboard /></RequireLogin>} />
              <Route path="models" element={<RequireLogin><Models /></RequireLogin>} />
              <Route path="printers" element={<RequireLogin><Printers /></RequireLogin>} />
              <Route path="cassettes" element={<RequireLogin><Cassettes /></RequireLogin>} />
              <Route path="octojob" element={<RequireLogin><OctoJob /></RequireLogin>} />
            </Route>
            <Route path="*" element={<RequireLoggedOut><Login /></RequireLoggedOut>} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider >
  );
}
export default App;


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
